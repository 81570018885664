import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Grid,
  TextField,
  Box,
  useMediaQuery,
  useTheme,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { DatePicker, TimePicker, LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import NextButton from 'src/components/Button/NextButton';
import { useDispatch, useSelector } from 'react-redux';
import { setPage, setWarnings } from 'src/store/reducers/registerReport';
import ProgressBar from 'src/layouts/Report/ProgressBar';
import { splitISODatetime, splitLocaleDatetime } from 'src/utils/formatTime';

function Page1() {
  const { date_time: datetime } = useSelector((state) => state.reportRegister.data);
  const role = useSelector((state) => state.reportRegister.data);
  const roleeid = useSelector((state) => state.reportRegister.data.role_id);
  let roleid = role?.user_id;
  const { edit } = useSelector((state) => state.reportRegister);
  
  const storedRoleId = localStorage.getItem('profile');
  const response = JSON.parse(storedRoleId);
  
  const dispatch = useDispatch();
  const role_id = response.data.id;
  
  if (roleid === undefined) {
     roleid = roleeid;
  }
  console.log(roleid, role_id);
  
if (datetime === null) {
  dispatch(setPage({ date_time: new Date(Date.now()).toISOString() }));
}

  useEffect(() => {
    if (datetime === null) {
      dispatch(setPage({ date_time: new Date(Date.now()).toISOString() }));
    } else {
      const selectedDate = new Date(datetime);
      const currentDate = new Date();
      
      // Check for future date
      const normalizedSelected = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
      const normalizedCurrent = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
      const isFutureDate = normalizedSelected > normalizedCurrent;
      
      // Check for future time (only if it's today)
      const isToday = normalizedSelected.getTime() === normalizedCurrent.getTime();
      const isFutureTime = isToday && (
        selectedDate.getHours() > currentDate.getHours() || 
        (selectedDate.getHours() === currentDate.getHours() && 
         selectedDate.getMinutes() > currentDate.getMinutes())
      );
      
      setFutureDateWarning(isFutureDate);
      setFutureTimeWarning(isFutureTime);
      dispatch(setWarnings({ 
        futureDateWarning: isFutureDate,
        futureTimeWarning: isFutureTime 
      }));
    }
  }, [datetime]);

  const changeDate = (e) => {
    const { date, time } = splitISODatetime(e);
    const newTime = datetime === null ? time : splitISODatetime(e)?.time;
    dispatch(setPage({ date_time: date + 'T' + newTime + 'Z'}));
  };

  const changeTime = (selectedHour, selectedMinute) => {
    // Create a new date based on the current dateValue to preserve the date
    const newDateTime = new Date(dateValue);
    // Set the hours and minutes
    const hours = amPmValue === 'pm' ? (selectedHour === 12 ? 12 : selectedHour + 12) : (selectedHour === 12 ? 0 : selectedHour);
    newDateTime.setHours(hours);
    newDateTime.setMinutes(selectedMinute);
    
    // Update Redux state with the new ISO string
    dispatch(setPage({ date_time: newDateTime.toISOString() }));
  };

  const theme = useTheme();
  const mnTime = (date) => {
    const dt = new Date(date);
    var newDate = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0, 0);
    return Number(newDate);
  };
  const dateNow = new Date(Date.now());

  const days = Array.from({ length: 31 }, (_, index) => index + 1);
  const months = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
  ];
  const datenow = new Date();
const currentYear = datenow.getFullYear();
const startYear = 1900;

const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => currentYear - index);


const dateTime=new Date(datetime||dateNow)
  
const [dateValue, setDateValue] = useState(dateTime); // Set the initial date value to the current local date
const [timeValue, setTimeValue] = useState(dateTime); // Set the initial time value to the current local time
const [amPmValue, setAmPmValue] = useState(dateTime.getHours() >= 12 ? 'pm' : 'am'); // Set the initial amPmValue based on the current local time
const [futureDateWarning, setFutureDateWarning] = useState(false); 
const [futureTimeWarning, setFutureTimeWarning] = useState(false);

  const handleDayChange = (e) => {
    const selectedDay = e.target.value;
    const selectedDate = new Date(dateValue);
    selectedDate.setDate(selectedDay);
    setDateValue(selectedDate);
    
    const currentDate = new Date();
    // Normalize dates to start of day for comparison
    const normalizedSelected = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
    const normalizedCurrent = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    
    const isFutureDate = normalizedSelected > normalizedCurrent;
    setFutureDateWarning(isFutureDate);
    dispatch(setWarnings({ futureDateWarning: isFutureDate }));
    
    // Set time to current time if the selected date is today
    if (normalizedSelected.getTime() === normalizedCurrent.getTime()) {
        setTimeValue(currentDate);
        setAmPmValue(currentDate.getHours() >= 12 ? 'pm' : 'am');
    }
    
    changeDate(selectedDate);
  };

  const handleMonthChange = (e) => {
    const selectedMonth = e.target.value;
    const selectedDate = new Date(dateValue);
    selectedDate.setMonth(selectedMonth - 1);
    setDateValue(selectedDate);
    
    const currentDate = new Date();
    // Normalize dates to start of day for comparison
    const normalizedSelected = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
    const normalizedCurrent = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    
    const isFutureDate = normalizedSelected > normalizedCurrent;
    setFutureDateWarning(isFutureDate);
    dispatch(setWarnings({ futureDateWarning: isFutureDate }));
    
    // Set time to current time if the selected date is today
    if (normalizedSelected.getTime() === normalizedCurrent.getTime()) {
        setTimeValue(currentDate);
        setAmPmValue(currentDate.getHours() >= 12 ? 'pm' : 'am');
    }
    
    changeDate(selectedDate);
  };

  const handleYearChange = (e) => {
    const selectedYear = e.target.value;
    const selectedDate = new Date(dateValue);
    selectedDate.setFullYear(selectedYear);
    setDateValue(selectedDate);
    
    const currentDate = new Date();
    // Normalize dates to start of day for comparison
    const normalizedSelected = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
    const normalizedCurrent = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    
    const isFutureDate = normalizedSelected > normalizedCurrent;
    setFutureDateWarning(isFutureDate);
    dispatch(setWarnings({ futureDateWarning: isFutureDate }));
    
    // Set time to current time if the selected date is today
    if (normalizedSelected.getTime() === normalizedCurrent.getTime()) {
        setTimeValue(currentDate);
        setAmPmValue(currentDate.getHours() >= 12 ? 'pm' : 'am');
    }
    
    changeDate(selectedDate);
  };

  const handleHourChange = (e) => {
    const selectedHour = parseInt(e.target.value, 10);
    const selectedTime = new Date(timeValue);
    // Convert to 24-hour format based on AM/PM
    const hours = amPmValue === 'pm' ? (selectedHour === 12 ? 12 : selectedHour + 12) : (selectedHour === 12 ? 0 : selectedHour);
    selectedTime.setHours(hours);
    setTimeValue(selectedTime);
    
    const currentDate = new Date();
    const isToday = dateValue.toDateString() === currentDate.toDateString();
    
    // Compare hours and minutes separately
    const isFutureTime = isToday && (
      hours > currentDate.getHours() || 
      (hours === currentDate.getHours() && 
       selectedTime.getMinutes() > currentDate.getMinutes())
    );
    
    setFutureTimeWarning(isFutureTime);
    dispatch(setWarnings({ futureTimeWarning: isFutureTime }));
    changeTime(selectedHour, selectedTime.getMinutes());
  };
  
  const handleMinuteChange = (e) => {
    const selectedMinute = parseInt(e.target.value, 10);
    const selectedTime = new Date(timeValue);
    selectedTime.setMinutes(selectedMinute);
    setTimeValue(selectedTime);
    
    const currentDate = new Date();
    const isToday = dateValue.toDateString() === currentDate.toDateString();
    
    // Only check for future time if it's today
    const isFutureTime = isToday && (
      selectedTime.getHours() > currentDate.getHours() || 
      (selectedTime.getHours() === currentDate.getHours() && 
       selectedMinute > currentDate.getMinutes())
    );
    
    setFutureTimeWarning(isFutureTime);
    
    // Only update the futureTimeWarning, don't touch the futureDateWarning
    const currentWarnings = {
      futureDateWarning,  // Keep existing date warning
      futureTimeWarning: isFutureTime
    };
    dispatch(setWarnings(currentWarnings));
    
    // Pass current hour to maintain AM/PM setting
    changeTime(selectedTime.getHours() % 12 || 12, selectedMinute);
  };
  
  
  
  const handleAmPmChange = (e) => {
    const selectedAmPm = e.target.value;
    setAmPmValue(selectedAmPm);

    const selectedTime = new Date(timeValue);
    const currentHours = selectedTime.getHours();
    const currentHour12 = currentHours % 12 || 12;
    
    // Convert to 24-hour format based on new AM/PM selection
    const newHours = selectedAmPm === 'pm' 
      ? (currentHour12 === 12 ? 12 : currentHour12 + 12)
      : (currentHour12 === 12 ? 0 : currentHour12);
    
    selectedTime.setHours(newHours);
    setTimeValue(selectedTime);

    const currentDate = new Date();
    const isToday = dateValue.toDateString() === currentDate.toDateString();
    
    // Compare full time objects to check if selected time is in the future
    const isFutureTime = isToday && selectedTime > currentDate;
    setFutureTimeWarning(isFutureTime);
    dispatch(setWarnings({ futureTimeWarning: isFutureTime }));
    
    dispatch(setPage({
      date_time: selectedTime.toISOString(),
    }));
  };
  
  
  
  const isMdBreakpoint = useMediaQuery(theme.breakpoints.up('md'));
  const hours = Array.from({ length: 12 }, (_, index) => index + 1);
  const minutes = Array.from({ length: 60 }, (_, index) => index);
// console.log(datetime)
let parts = datetime?datetime.split(/[-T]/):""; // Split the string using '-' and 'T' as delimiters
let year = parts[0]; // Extract the year
let month = parts[1]; // Extract the month
let day = parts[2]; // Extract the day
// day = day.length === 2 ? day.replace(/^0/, '') : day;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="sm" style={{ padding: theme.spacing(5, 0) }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography
                variant="h1"
                sx={{
                  textAlign: 'center',
                  borderBottom: `2px solid ${theme.palette.warning.main}`,
                  paddingBottom: '2px',
                }}
                className="font-bold pb-2 text-3xl"
              >
                {edit?(role_id===roleid?"Edit Crime":"Suggest A Change"):"Report Crime"}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              paddingTop={isMdBreakpoint ? '40px' : '80px'}
              paddingBottom={isMdBreakpoint ? '40px' : '35px'}
            >
              <Box borderBottom={2} borderColor={theme.palette.warning.main} style={{ marginRight: '5px', width: '20px' }} />
              <Typography variant="h4" sx={{ fontWeight: 'normal', textAlign: 'center', paddingTop: '0px', paddingBottom: '0px' }}>
                Select Date
              </Typography>
              <Box borderBottom={2} borderColor={theme.palette.warning.main} style={{ marginLeft: '5px', width: '20px' }} />
            </Box>
          </Grid>
          <Grid item xs={3} sx={{ textAlign: 'center' }}>
            <TextField
              select
              label="Day"
              value={dateValue.getDate()}
              onChange={handleDayChange}
              fullWidth
            >
              {days.map((day) => (
                <MenuItem key={day} value={day}>
                  {day}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={3} sx={{ textAlign: 'center' }}>
            <TextField
              select
              label="Month"
              value={dateValue.getMonth() + 1}
              onChange={handleMonthChange}
              fullWidth
            >
              {months.map((month) => (
                <MenuItem key={month.value} value={month.value}>
                  {month.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={3} sx={{ textAlign: 'center' }}>
            <TextField
              select
              label="Year"
              value={ dateValue.getFullYear()}
              onChange={handleYearChange}
              fullWidth
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {futureDateWarning && ( // Display the warning if futureDateWarning is true
            <Grid item xs={7}>
              <Typography variant="body2" color="error">
                Please note that the selected date is in the future.
              </Typography>
            </Grid>
          )}
          <Box style={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                paddingTop={isMdBreakpoint ? '40px' : '80px'}
                paddingBottom={isMdBreakpoint ? '40px' : '40px'}
              >
                <Box borderBottom={2} borderColor={theme.palette.warning.main} style={{ marginRight: '5px', width: '20px' }} />
                <Typography variant="h4" sx={{ fontWeight: 'normal', textAlign: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                  Select Time
                </Typography>
                <Box borderBottom={2} borderColor={theme.palette.warning.main} style={{ marginLeft: '5px', width: '20px' }} />
              </Box>
            </Grid>
            <Box display="flex" flexDirection="row" width="100%" justifyContent='end'>
              <Grid item xs={3.8} sx={{ textAlign: 'center',paddingX: '5px' }}>
                <TextField select label="Hour" value={timeValue.getHours() % 12 || 12} onChange={handleHourChange} fullWidth>
                  {hours.map((hour) => (
                    <MenuItem key={hour} value={hour}>
                      {hour}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={3.9} sx={{ textAlign: 'center', width: '100%',paddingX: '5px' }}>
                <TextField select label="Minute" value={timeValue.getMinutes()} onChange={handleMinuteChange} fullWidth>
                  {minutes.map((minute) => (
                    <MenuItem key={minute} value={minute}>
                      {minute.toString().padStart(2, '0')}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={3.9} sx={{ textAlign: 'center', width: '100%', paddingX: '5px' }}>
                <TextField
                  select
                  label="AM/PM"
                  value={amPmValue}
                  onChange={handleAmPmChange}
                  fullWidth
                >
                  <MenuItem value="am">AM</MenuItem>
                  <MenuItem value="pm">PM</MenuItem>
                </TextField>
              </Grid>
              
            </Box>
          </Box>
{futureTimeWarning && ( // Display the warning if futureTateWarning is true
            <Grid item xs={7} sx={{ textAlign: 'center' }}>
              <Typography variant="body2" color="error">
                Please note that the selected time is in the future.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </LocalizationProvider>
  );
}

export default Page1;